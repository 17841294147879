@function get-vw($size) {
    @return ($size / 1920) * 100vw;
}

$font-montserrat: 'Montserrat', sans-serif;
$font-cormorant-garamond: 'Cormorant Garamond', serif;
$font-wind-song: 'WindSong', cursive;
$font-martel: "Martel Sans", sans-serif;
$font-quicksand: "Quicksand", sans-serif;

$theme-main-color:#C8AB70;
$theme-dark-green:#111D22;
$theme-dark-green-main:#092F2B;
$theme-black:#404040;
$theme-dark-black:#3D3D3D;
$theme-off-white:#ECE2DB;
$theme-orange:#26000A;

$font-size-10: get-vw(10);
$font-size-11: get-vw(11);
$font-size-12: get-vw(12);
$font-size-13: get-vw(13);
$font-size-14: get-vw(14);
$font-size-15: get-vw(15);
$font-size-16: get-vw(16);
$font-size-18: get-vw(18);
$font-size-20: get-vw(20);
$font-size-22: get-vw(22);
$font-size-25: get-vw(25);
$font-size-27: get-vw(27);
$font-size-30: get-vw(30);
$font-size-35: get-vw(35);
$font-size-40: get-vw(40);
$font-size-45: get-vw(45);
$font-size-50: get-vw(50);
$font-size-65: get-vw(65);
$font-size-70: get-vw(70);
$font-size-150: get-vw(150);
$font-size-250: get-vw(250);
$font-size-380: get-vw(380);
$font-size-420: get-vw(420);
@import "./variables.scss";

@function get-px($size) {
    @return ($size / 100vw) * 1920px;
}

.banner_section{
    position: relative;
    background: $theme-dark-green;
    video{
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }
    .banner_logo{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    .banner_section_main_img{
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }
}
.banner_section::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #1B1525;
    opacity: 0.47;
}
.scrolldown{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .scrolldown_dot{
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background: $theme-main-color;
        margin-bottom: 8px;
    }
    .scrolldown_line{
        width: 2px;
        height: 100px;
        background: $theme-main-color;
    }
    .scrolldown_square{
        width: 10px; 
        height: 10px; 
        background-color: $theme-main-color;
        position: relative;
        transform: rotate(45deg); 
        transform-origin: bottom left;
        clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
        margin-left: -3px;
        margin-top: 40px;
    }
}
.city_bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit:cover ;
    object-position: bottom;
}
.white_mesh{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: 100%;
    z-index: 1;
}
.city_view{
    height: 100vh;
    position: relative;
    h6{
        padding-bottom: 20px;
        width: 6vw;
        text-align: center;
    }
    .city_view_box_0{
        bottom: 45vh;
    left: 28vw;
    }
    .city_view_box_1{
        bottom: 43vh;
    left: 36vw;
    }
    .city_view_box_2{
        bottom: 35vh;
        right: 39vw;
    }
    .city_view_box_3{
        bottom: 50vh;
        right: 27vw;
    }
    .city_view_box_4{
        bottom: 48vh;
        right: 20vw;
    }
    .city_view_box_5{
        bottom: 40vh;
        right: 9vw;
    }
    .city_view_box{
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
       
        .city_view_box_details{
            opacity: 0;
            border: 1px solid $theme-main-color;
            padding: 0;
            width: 15vw;
            text-align: center;
            margin-bottom: 15px;
            border-bottom: 0 !important;
            p{
                padding: 20px 20px 20px 20px;
                margin: 0;
            }
            .city_view_box_details_line{

            }
        }
    }
    
    .city_view_box_pin{
        animation: float 2s infinite ease-in-out;
        height: 50px;
        width: 50px;
        object-fit: scale-down;
    }
}
@keyframes float {
    0%, 100% {
        transform: translate(0%, 0);
    }
    50% {
        transform: translate(0%, -10px);
    }
}
.awrads_box{
    position: relative;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    text-align: center;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
// .awrads_box::after{
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background-image: url('/home/awards.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
// }
.awrads_box_text{
    position: relative;
    height: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    text-align: center;
    img{
        margin: 10px 0;
    }
}
.dine_section{
    .dine_section__text{
        width: 30%;
        background-color: $theme-off-white;
        padding-left: 5%;
        padding-right: 3%;
    }
    .dine_section__image{
        width: 25%;
        position: relative;
        .dine_section__image_wrap{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 115%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .dine_section__image_wrap::after{
            background: rgb(0 0 0 / 31%);
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .dine_section__slider{
        width: 45%;
        // background: $theme-dark-green;
        overflow: hidden;
    }
}
.dine_box{
    position: relative;
    .dine_box__title{
        position: absolute;
        bottom: -8vw;
        left: 0;
        right: 0;
        padding: 0px 5vw 0 5vw;
        text-align: center;
        width: 29vw;
        display: flex;
        align-items: flex-start;
        height: 8vw;
        justify-content: center;
    }
}
.corner_bottom{
    position: absolute;
    bottom: 2vw;
    left: 2vw;
}
.corner_bottom_right{
    position: absolute;
    bottom: 2vw;
    right: 2vw;
}
.corner_top{
    position: absolute;
    top: 2vw;
    right: 2vw;
}
.download_menu{
    position: absolute;
    bottom: 4vw;
    right: 4vw;
    z-index: 5;
    img{
        margin-bottom: 20px;
    }
}
.drinks_div{
    width: 33.3%;
    height: 51vw;
    video{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.drinks_div__img{
    position: relative;
    overflow: hidden;
    .drinks_div__img_main{
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s;
    }
    .drinks_div__img_btn{
        position: absolute;
        bottom: 10vh;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
    }
}
.drinks_div__img:hover{
    .drinks_div__img_main{
        transform: scale(1.1);
        transition: all 0.5s;
    }
}

.drinks_div__img::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 99.27%);
}
.drinks_div__text{
    background: $theme-off-white;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    text-align: center;
    a{
        margin-left: auto;
        margin-right: auto;
    }
    ul{
        list-style: none;
        padding: 0 0 20px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        li{
            display: flex;
            align-items: center;
        }
        li:last-child{
            img{
                display: none;
            }
        }
        img{
            margin: 0 10px;
        }
    }
}
.entertain_text{
    width: 28%;
    background: $theme-off-white;
    text-align: center;
    padding-left: 2vw;
    padding-right: 2vw;
    a{
        margin-left: auto;
        margin-right: auto;
    }
}
.entertain_slider{
    width: 72%;
    background: $theme-black;
    img{
        width: 100%;
        height: 40vw;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s;
    }
}
.entertain_slider_div{
    position: relative;
    overflow: hidden;
    .entertain_slider_div_text{
        position: absolute;
        bottom: 5vh;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0 2vw;
        z-index: 1;
    }
}
.entertain_slider_div:hover{
    img{
        transform: scale(1.1);
        transition: all 0.5s;
    }
}
.entertain_slider_div{
    img{
        transform: scale(1);
        transition: all 0.5s;
    }
}
.entertain_slider_div::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 59.67%, rgba(0, 0, 0, 0.65) 92.67%);
}
.seen_sec{
    padding-top: 40px;
    .seen_left{
        width: 40%;
        padding-left: 1vw;
    }
    .seen_right{
        width: 50%;
    }
}
.gallery_slider {

    img{
        width: auto;
        height: 27vw;
        object-fit: cover;
    }
}
.offer_box{
    .offer_box_left{
        width: 45%;
        background: $theme-off-white;
        text-align: center;
        padding: 1vw;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            margin-left: auto;
            margin-right: auto;
        }
    }
    .offer_box_right{
        width: 55%;
        position: relative;
        overflow: hidden;
        img{
            height: 25vw;
            width: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all 0.5s;
        }
    }
}
.offer_box:hover{
    .offer_box_right{
        img{
            transform: scale(1.1);
            transition: all 0.5s;
        }
    }
}
.testimonial_left{
    width: 40%;
}
.testimonial_right{
    width: 50%;
    position: relative;
}
.grid {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2vw;
}

.ver_gallery{
    display: flex;
    .ver_gallery_left{
        width: 50%;
        .ver_gallery_left__sticky{
            height: 100vh;
            width: 200%;
            display: flex;
        }
    }
    .ver_gallery_right{
        width: 50%;
        z-index: 1;
        .ver_gallery_right_inner{
            width: 200%;
            height: 100%;
            margin-left: -100%;
            display: flex;
        }
    }
}
.ver_gallery_box_text{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: auto;
        height: 90%;
    }
}
.ver_gallery_wrap{
    overflow: hidden;
    background: $theme-off-white;
}
.ver_gallery_box_div{
    width: 30%;
    img{
        width: 100%;
    }
}
.ver_gallery_box_text{
    width: 10%;
}
.ver_gallery_box_div_one{
    position: relative;
    img{
        height: 68vh;
        padding: 1vh 0;
        width: 100%;
        object-fit: cover;
        // border-top-left-radius: 300px;
        // border-top-right-radius: 300px;
    }
    .ver_gallery_box_div_one_img_top{
        position: absolute;
        top: -68vh;
        left: 0;
    }
    .ver_gallery_box_div_one_img_bottom{
        position: absolute;
        top: 236vh;
        left: 0;
    }
    .ver_gallery_box_div_one_img_bottom_two{
        position: absolute;
        top: 272vh;
        left: 0;
    }
}

.ver_gallery_box_div_three{
    position: relative;
    img{
        height: 68vh;
        padding: 1vh 0;
        width: 100%;
        object-fit: cover;
        // border-bottom-left-radius: 300px;
        // border-bottom-right-radius: 300px;
    }
    .ver_gallery_box_div_three_img_top{
        position: absolute;
        top: -68vh;
        left: 0;
    }
    .ver_gallery_box_div_three_img_bottom{
        position: absolute;
        top: 236vh;
        left: 0;
    }
    .ver_gallery_box_div_three_img_bottom_two{
        position: absolute;
        top: 272vh;
        left: 0;
    }
}
.ver_gallery_box_div_two{
    position: relative;
    img{
        height: 98vh;
        width: 97%;
        padding: 1vh 0;
        object-fit: cover;
    }
    .ver_gallery_box_div_two_img{
        position: absolute;
        top: 196vh;
        left: 0;
        right: 1vw;
    }
}
.ver_gallery_box_div_one_square{
    border-radius: 0 !important;
    height: 50vh !important;
}
.offer_box_right_tag{
    position: relative;
    height: 30px;
    padding: 0 20px;
    border-radius: 40px;
    background: $theme-main-color;
    color: $theme-black;
    text-transform: uppercase;
    font-size: $font-size-12;
    font-family: $font-quicksand;
    z-index: 2;
    display: flex;
    align-items: center;
    top:auto;
    right: auto;
    font-weight: 500;
    width: max-content;
    margin:0 auto 15px auto;
}
.sin_section{
    background-color: $theme-orange;
    background-image: url('/overlay.png');
    .sin_section__logo{
        height: auto;
        width: 30vw;
        margin: auto;
        display: block;
    }
    .sin_div{
        img{
            width: 100%;
            height:35vw;
            object-fit: cover;
        }
    }
}
.sin_slider_div{
    padding: 0 1vw;
}
.sin_slider{
    padding: 2vw 0 3vw;
}
@media (min-width: 1025px) and (max-width: 1300px) {
    .city_view{
        h6{
            width: 10vw;
        }
    }
}
@media (min-width: 1025px) and (max-width: 1030px) {
    
}
@media only screen and (min-width: 1025px) {
    
}
@media only screen and (max-width: 1025px) {
    .dine_section__image_wrap{
        width: 100% !important;
        position: relative !important;
    }
    .sin_slider_div{
        padding: 0 20px;
    }
    .sin_slider{
        padding:0 0 30px 0;
    }
    .banner_section{
        .banner_logo{
            img{
                width: 80%;
            }
        }
    }
    .dine_section{
        .dine_section__text{
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }
        .dine_section__image{
            width: 100%;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .dine_section__slider{
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    .download_menu{
        position: relative;
        bottom: auto;
        right: auto;
    }
    .dine_box{
        .dine_box__title{
            position: relative;
            bottom: auto;
            height: 80px;
            padding: 10px 20px 0 20px;
            width: 100%
        }
    }
    .drinks_div_wrap{
        flex-direction: column-reverse;
    }
    .drinks_div{
        width: 100%;
    }
    .drinks_div__text{
        padding-left: 5%;
        padding-right: 5%;
    }
    .entertain_text{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .entertain_slider{
        width: 100%;
        img{
            width: 100%;
            height: 500px;
        }
    }
    .entertain_slider_div{
        .entertain_slider_div_text{
            bottom: 80px;
            padding: 0 30px;
        }
    }
    .seen_sec{
        .seen_left{
            width: 100%;
            padding-left: 0%;
        }
        .seen_right{
            width: 100%;
        }
    }
    .offer_box{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .offer_box_left{
            width: 100%;
            padding: 30px 20px;
        }
        .offer_box_right{
            width: 100%;
            position: relative;
            img{
                height: 250px;
                width: 100%;
            }
        }
    }
    .testimonial_left{
        width: 100%;
    }
    .testimonial_right{
        width: 100%;
    }
    .city_view{
        height: 500px;
        position: relative;
        .city_view_box{
            display: none;
        }
    }
    .awrads_box{
        height: 200px;
        padding: 30px 20%;
    }
    .awrads_box_text{
        height: 200px;
    }
    .culinary_gallery{
        display: none;
    }
    .offer_box_right_tag{
        font-size:get-px($font-size-12);
        height: 20px;
        padding: 0 20px;
    }
    .gallery_slider {

        img{
            height: 300px;
        }
    }
    .dine_section__image{
        img{
            position:relative !important;
            height: 450px !important;
        }
    }
    .dine_section__slider{
        padding-top: 0 !important;
        margin-top: -50px;
    }
    .drinks_div{
        video{
            height: 400px;
        }
    }
    .download_menu{
        padding-top: 30px;
    }
}
@media (min-width: 600px) and (max-width: 1025px) {
    .banner_section{
        video{
            height: 600px;
        }
        .banner_section_main_img{
            height: 600px;
        }
    }
    .sin_section{
        .sin_div{
            img{
                height:400px;
            }
        }
    }
    .dine_section{
        .dine_section__text{
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }
        .dine_section__image{
            width: 40%;
            position: relative;
            img{
               height: 100% !important;
            }
        }
        .dine_section__slider{
            padding-top: 50px !important;
            width: 60%;
            margin: 0;
        }
    }
    .entertain_slider_div{
        .entertain_slider_div_text{
            bottom: 30px !important;
        }
    }
    .drinks_div{
        height: auto;
    }
    .drinks_div__text{
        padding-left: 15%;
        padding-right: 15%;
    }
    .drinks_div_video,.drinks_div__img{
        width: 50%;
    }
    .drinks_div_wrap{
        flex-direction: row-reverse;
    }
    .drinks_div_video{
        video{
            height: 100%;
        }
    }
    .dine_section{
        .dine_section__text{
            padding-left: 15%;
            padding-right: 15%;
        }
    }
    .entertain_text{
        padding-left: 15%;
        padding-right: 15%;
    }
}
@media (min-width: 991px) and (max-width: 1025px){
    .gallery_slider {

        img{
            height: 350px;
        }
    }

}
@media only screen and (max-width: 600px) {
    .offer_box_left{
        justify-content: center;
        width: 100%;
    }
    .drinks_div{
        height: auto;
    }
    .banner_section{
        video{
            height: 400px;
        }
        .banner_section_main_img{
            height: 400px;
        }
    }
    .sin_slider_div{
        padding: 0 0px;
    }
    .sin_section{
        .sin_section__logo{
            width: 90%;
        }
        .sin_div{
            img{
                height:350px;
            }
        }
    }

}